import React from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, List, ListItem, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';

export const Licenses = () => {

  const tiers = [
    {
      title: 'Basic',
      price: '0',
      description: [
        'Unlimited collaboration on Commentaries owned by other users',
        'Create up to 12 Commentaries',
      ],
      buttonText: 'Get started with Basic',
      buttonVariant: 'outlined',
      buttonURL: 'https://available-inventions.umich.edu/product/anotemos'
    },
    {
      title: 'Advanced',
      subheader: 'Most popular',
      price: '500',
      description: [
        'Unlimited collaboration on Commentaries owned by other users',
        'Create unlimited Commentaries',
        'Download up to 4 Commentary Reports per year',
        'Add up to 1 Temporary user per Commentary',
      ],
      buttonText: 'Get started with Advanced',
      buttonVariant: 'contained',
      buttonURL: 'https://available-inventions.umich.edu/product/anotemos'
    },
    {
      title: 'Premium',
      price: '1250',
      description: [
        'Unlimited collaboration on Commentaries owned by other users',
        'Create unlimited Commentaries',
        'Download unlimited Commentary reports',
        'Add unlimited Temporary users to Commentaries',
        'Access to Team accounts',
      ],
      buttonText: 'Get started with Premium',
      buttonVariant: 'outlined',
      buttonURL: 'https://available-inventions.umich.edu/product/anotemos'
    },
  ];

  return (
    <React.Fragment>
      <Divider sx={{ marginTop: 5 }}>
        <Typography variant="h5" align="center" color="primary.main" component="p">
          Licenses
        </Typography>
      </Divider>
      <Grid container spacing={2} my={2}>
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={4}
            md={4}
          >
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: 'center' }}
                action={tier.title === 'Advanced' ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: 'center',
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    ${tier.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    /yr
                  </Typography>
                </Box>
                <List dense={true}>
                  {tier.description.map((line, index) => (
                    <ListItem sx={{ display: 'list-item', listStyleType: 'disc', ml: 2, pl: 1 }} key={tier.title + index}>
                      <Typography variant="subtitle1">
                        {line}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  href={tier.buttonURL}
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
