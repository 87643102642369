import React from 'react';
import { AppBar, Box, Button, Container, CssBaseline, GlobalStyles, Link, Toolbar, Typography } from '@mui/material';
import { Features } from './Features';
import { Licenses } from './Licenses';

export const App = () => {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar variant='dense' sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box>
            <Link sx={{ display: 'flex', alignContent: 'center' }} href="/">
              <img src="/anotemos_logo.png" alt="Anotemos Logo" height="60" />
            </Link>
          </Box>
          <Button href="https://www.gripumich.org/contact" target='_blank' variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Contact Us
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar variant='dense' />
      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6, px: { xs: 2 } }}>
        <img src="/anotemos_logo.png" alt="Anotemos" style={{ maxWidth: '90%' }} />
        <Typography sx={{ typography: { sm: 'h5', xs: 'h6' } }} align="justify" color="text.secondary" component="p">
          <Typography component="span" sx={{ float: 'left', lineHeight: '120%', fontSize: '220%', color: '#6E98C7', pr: 1 }}>A</Typography>notemos, is a robust media annotation software designed to be used by educators and their students. Using Anotemos, users can create Commentaries that are centered around a media item (Video, Sequence of images) to add annotations. Anotemos allows multiple users to view a Commentary together and comment on them, on or off the screen. A scalable range of tools is available for this use, including pins, clips, marquee, voice annotations, and the possibility to add graphics or marks to the screen. These functionalities allow users to parse media with high attention to detail, hence treating any video, slideshow, or image as if it were a text. Anotemos does not stream videos, rather it uses videos streamed by a variety of providers (YouTube, Vimeo, Kaltura, Google Drive).
        </Typography>
        <Button href="https://www.anotemos.org/" target='_blank' fullWidth='true' variant="contained" sx={{ my: 2 }}>
          Login
        </Button>
        <Features />

        <Licenses />
      </Container>
    </React.Fragment>
  );
}
