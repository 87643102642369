import React from 'react';
import { Alert, Avatar, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CollectionsBookmarkRounded, GroupRounded, HandymanRounded, LoginRounded, SchoolRounded } from '@mui/icons-material';

export const Features = () => {

  const classroom_pilot_description = () => {
    return (
      <React.Fragment>
        Anotemos is now available on UM's Canvas. The Anotemos development team is looking for University of Michigan Instructors to broadly pilot this software tool in their classrooms.
        <br /><br />
        ARE YOU?<br />
        - Teaching in Winter, Spring or Fall <br />
        - Using videos in your teaching material <br />
        - Wanting to create new collaborative experiences
        <br /><br />
        If so, please contact us via email at <Link href="mailto:grip@umich.edu">grip@umich.edu</Link> for a demo of the tool and to discuss how we can help integrate Anotemos to enhance your teaching.
      </React.Fragment>
    )
  }

  const features = [
    {
      title: 'Sharing',
      icon: <Avatar sx={{ width: 72, height: 72 }}><GroupRounded fontSize='large' /></Avatar>,
      description: 'Anotemos supports the sharing of commentaries with one or multiple users. These new collaborators can view and annotate with the tools laid out by the commentary owner. Shared users may also view some, or all, comments made by their peers depending on the sharing options specified by the owner. Anotemos offers many options when it comes to tailoring a commentary to a specific piece of media.'
    },
    {
      title: 'Tools',
      icon: <Avatar sx={{ width: 72, height: 72 }}><HandymanRounded fontSize='large' /></Avatar>,
      description: 'Each Commentary has access to a suite of annotating tools including screen sketches, audio comments, text comments, and emotes. The use and meaning of each and all of these tools is fully customizable, allowing the owner to setup different kinds of activities for clients in which varying arrays of tools are made available.'
    },
    {
      title: 'Grouping',
      icon: <Avatar sx={{ width: 72, height: 72 }}><CollectionsBookmarkRounded fontSize='large' /></Avatar>,
      description: 'Commentaries on the same piece of media can be grouped together allowing for streamlined edits and tweaks from the owner. These commentaries can all be shared to different users, all of whom will be unaware of such grouping. This allows owners to make sweeping modifications to multiple commentaries at once by editing one main commentary. Grouping sections can vastly diminish the workload when creating multiple identical sections for different users.'
    },
    {
      title: 'Access',
      icon: <Avatar sx={{ width: 72, height: 72 }}><LoginRounded fontSize='large' /></Avatar>,
      description: 'All the users of Anotemos can invite new users to a Commentary by adding their emails. If you receive an invitation to join a Commentary, a basic Anotemos account will be created when you join that commentary. You can upgrade your account by purchasing one of the licenses below.'
    },
    {
      title: 'Classroom Pilots',
      icon: <Avatar sx={{ width: 72, height: 72 }}><SchoolRounded fontSize='large' /></Avatar>,
      description: classroom_pilot_description()
    },
  ];

  return (
    <React.Fragment>
      <Divider sx={{ marginTop: 5 }}>
        <Typography variant="h5" align="center" color="primary.main" component="p">
          Features
        </Typography>
      </Divider>
      <Grid container spacing={2} sx={{ my: 2, px: { sm: 5, xs: 2 } }}>
        <List dense={true}>
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemIcon sx={{ mr: 2 }}>
                  {feature.icon}
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {feature.title}
                  </Typography>
                  <Divider sx={{ borderBottomWidth: 2, borderBottomColor: 'primary.main', width: 40, mb: 2 }} />
                  <Typography sx={{ textAlign: 'justify' }}>
                    {feature.description}
                  </Typography>

                  {feature.title === 'Classroom Pilots' && <Alert component='span' severity="info" sx={{ my: 2 }}>
                    Software and implementation support is available from the software development team and from UM ITS.
                  </Alert>}
                </ListItemText>
              </ListItem>
              <Divider sx={{ my: 2 }} />
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </React.Fragment>
  );
}
