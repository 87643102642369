import React from 'react';
import { useRoutes } from 'react-router-dom';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import { App } from './App';
import { Redirector } from './Redirector';


export const RenderRoutes = () => {
  return useRoutes([
    { path: '/', element: <App /> },
    { path: '*', element: <Redirector /> },
  ]);
}

// const ErrorPage = () => {
//   let error = {
//     status: 404,
//     statusText: "Page not found",
//     data: "The page you are looking for does not exist."
//   };

//   const goBack = () => {
//     window.history.back() || window.location.replace('/');
//   }

//   return (
//     <Grid
//       container
//       spacing={0}
//       direction="column"
//       alignItems="center"
//       justifyContent="center"
//       style={{ minHeight: '80vh' }}
//     >
//       <Typography variant="h1">
//         {error.status}
//       </Typography>
//       <Typography variant="h4">
//         {error.statusText}
//       </Typography>
//       <Typography variant="body1" gutterBottom>{error.data}</Typography>
//       <Link component="button" onClick={goBack}>Go back</Link>
//     </Grid>
//   )
// }